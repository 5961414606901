
$(document).ready(function() {
	$(document).foundation();

  	$('.safety-wrap').css({'display': 'inline-block'});
  	var safetyWidth = $('.safety-wrap').width();
  	var safetyHeight = $('.safety-wrap').height();
  	var iwd = isWindowDesktop();

	setSafetyHorizPosition(safetyWidth);

  	setTopMargin();
	
	$( window ).resize(function(){
	  	setSafetyHorizPosition(safetyWidth);
	  	setTopMargin(safetyHeight);
	  	iwd = isWindowDesktop();
	  	console.log(iwd);
	});

	function isWindowDesktop(){
		return $(window).width() > 1023;
	}
	function setTopMargin(){
		if ( !iwd ) {
			$('.logo-bar').css({'padding-top': safetyHeight});
		} else {
			$('.logo-bar').css({'padding-top': '0px'});
			$('.safety-wrap').css({ 'background-color': 'transparent' });

		}
	}
	function setSafetyHorizPosition(safetyWidth){
		if ( isWindowDesktop() ){
			//get the left offset of the menu bar, position safety button 20px offset from the right
		 	var sPosition = $('.logo-bar').width() - (safetyWidth+20) +'px';
			$('.safety-wrap').css({ 'left': sPosition, 'text-align': 'left', 'width': 'auto', 'background-color': 'transparent'  });
		}else {
			$('.safety-wrap').css({ 'left': 0, 'text-align': 'center', 'width': '100%'});
		}
	}

});



